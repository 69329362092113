<template>
    <div class="card-header border-bottom pt-1 pb-1">
      <h4 class="card-title">Address</h4>
    </div>
  
    <div class="card-body mt-2">
        <form class="invoice-repeater">

            <div class="row d-flex align-items-end">
                <div class="col-md-5 col-5 card_body box_shadow">
                    <div class="row d-flex align-items-end">
                        <div class="col-md-12 col-12">
                            <div class="mb-1">
                                <label class="form-label">Select Bank-Branch</label>
                                <AsyncSelect
                                    placeholder="Select Bank-Branch"
                                    v-model="dealerId"
                                    :api-service="fetchAllDealers"
                                    :reduce="option => option.id"
                                    :additional-option="additionalDealerOption"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="row mt-2">
                <div class="col-sm-12">
                    <button 
                        :disabled="loading" @click="submit"
                        class="btn btn-primary me-1 waves-effect waves-float waves-light"
                    >
                        <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        Submit
                    </button>
                    <button type="button" class="btn btn-outline-secondary">Reset</button>
                </div>
            </div>

        </form>
    </div>
</template>
  
<script setup>
    import { defineProps, inject, onMounted, ref, watch } from "vue";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import { useAsyncDropdownHelper } from "@/services/utils/asyncDropdownHelper";
    import handleCrm from '@/services/modules/crm/crm'
    import handleCompanyContact from "@/services/modules/companyContact";
    import { useRoute } from "vue-router";

    const props = defineProps({
        companyRules: {
            type: Array,
            default: []
        },
        items: {
            type: Array,
            default: []
        },
        formData: {
            type: Object
        }
    })
    
    const { fetchAllDealers } = handleCrm();
    const { updateContactDealer } = handleCompanyContact();
    const route = useRoute();

    const additionalDealerOption = ref([]);
    const dealerId = ref(null);
    const loading = ref(false);
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');

    const submit = async () => {

        const contactId = props.formData.id;
        if(!contactId){
            return showError('Contact id is required');
        }

        const formData = {
            company_id: route.params.companyId,
            dealer_id: dealerId.value
        };

        loading.value = true

        await updateContactDealer(contactId, formData).then((res) => {
            loading.value = false
            if (!res.status) return showError(res.message);
            return showSuccess(res.message);
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            loading.value = false
        })

    }

    watch(() => props.formData, (newFormData) => {
        formDataSet();
    });

    const formDataSet = () => {
        if (props.formData.dealer_id) {

            dealerId.value = props.formData.dealer_id

            additionalDealerOption.value.push({
                id: props.formData.dealer_id,
                name: props.formData.dealer && props.formData.dealer.contact_profile.full_name || ''
            })
        }
    }

    onMounted(() => {
        formDataSet()
    })
  
</script>
  
  